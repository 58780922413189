/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).ready( function() {

          new WOW().init();



          $('body').waitForImages().done(function() {
              // alert('load');
              $('.spinner').fadeOut(700);
          });

          $( window ).resize(function() {
            var windowHeight = $(window).height();
            var windowWidth = $(window).width();

            if(windowWidth >= 1024){
                $('.fit-screen').height(windowHeight - 190); // 181 correspond à la taille du header + celle du footer
            } else {
                $('.fit-screen').height('auto');
            }

            if($('#pageID').hasClass('page-laboratoire')) {
              // page laboratoire

              $('.menu-bar').css('background-color','rgba(0,0,0,0)');
              // console.log('ok ok');

              if(windowWidth >= 1024){
                var heightLaboAddress = $('section.fixed').height();
                $('section.images-list').css('margin-top', heightLaboAddress);
              } else {
                $('section.images-list').css('margin-top', '0');
              }
            }

            if($('#pageID').hasClass('page-projetID')) {
              $('#pageID').css('padding-bottom','0');
              $('footer').css('position','relative');
              // scroll bouton apparait dans le footer
              $(window).scroll(function(){
                if ($(this).scrollTop() >= 900) {
                  $('#btn-top').fadeIn();
                } else {
                  $('#btn-top').fadeOut();
                }
              });

              // page projet id
              var heightBlocRight = $('.bloc-droite').height();


              // je définis la taille du bloc de gauche
              if($(window)>768) {
                $('.bloc-gauche').height(heightBlocRight);
              }


              var auto = 'auto';

              // au clic on agrandit le bloc
              $('#arrow-height').on('click', function(e) {
                $('.bloc-gauche').addClass("height-auto");
                $('#arrow-height').css('display','none');
                $('#arrow-top').css('display','block');
                $('.full_text').css('display','block');

                e.preventDefault();
              });

               $('#arrow-top').on('click', function(e) {
                $('.bloc-gauche').removeClass("height-auto");
                $('#arrow-height').css('display','block');
                $('#arrow-top').css('display','none');
                $('.full_text').css('display','none');

                e.preventDefault();
              });
            }

            if($('#pageID').hasClass('page-equipe')) {

              // $('.item').height($('#fix-equipe-height').find('<img />').height());
              // var height-equipe = $('fix-equipe-height').height();
              // console.log(height-equipe);
              // $( "img" ).load(function() {
                                // console.log('ok');
                                // var equipeHeight = $('.height-equipe').height();
                                // // console.log(equipeHeight);
                                // $('.item').height(equipeHeight);
              // });


              var total_images = $("body img").length;
              var images_loaded = 0;

              var nbrePers = $('.item').length;

              if(nbrePers > 3) {
                $('.fit-screen').height('auto');
              } else {
                $('.fit-screen').height(windowHeight - 190);
              }


              $("body").find('img').each(function() {
                    var fakeSrc = $(this).attr('src');
                    $("<img/>").attr("src", fakeSrc).css('display', 'none').load(function() {
                        images_loaded++;
                        if (images_loaded >= total_images) {
                            // NOW ALL IMG LAODD
                            $("body img").show();
                            // console.log('ok');
                            var equipeHeight = $('.height-equipe').height();
                            // console.log(equipeHeight);

                            if(windowWidth <= 1024){
                              $('.fit-screen').height('auto');
                              $('.item').height('auto');
                            }else {
                              $('.item').height(equipeHeight);
                            }
                        }
                    });
              });

            }

          });

          $(window).trigger('resize');

          // MENU
          $('.burger-menu').click(function() {
            $('nav').removeClass('menu-closed');
            $('nav').addClass('menu-opened');
          });

          $('.close-icon>a').click(function() {
            $('nav').removeClass('menu-opened');
            $('nav').addClass('menu-closed');
          });

          $( document ).on( 'keydown', function ( e ) {
            if ( e.keyCode === 27 ) { // ESC
              $('#navID').removeClass('menu-opened');
              $('#navID').addClass('menu-closed');
            }
          });

        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'laboratoire': {
        init: function() {
          // JavaScript to be fired on the home page
          $('main').css('padding-left','0');
          $('main').css('padding-right','0');
        }
      },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('main').css('padding-left','0');
        $('main').css('padding-right','0');
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
